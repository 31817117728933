<script setup>

import { useBackend } from "~/composables/Backend";

const backend = useBackend();
const {leasingParamsDefinition, getCurrentParams, setCurrentParams} = useLeasingParams()
const digitalOrder = useDigitalOrder();

const refBrands = ref();
const refModels = ref();
const refVersions = ref();

const modelData = ref({
  client: 'entrepreneur',
  brand: [],
  model: [],
  version: [],
  year: [],
  state: [],
  price: null
});

const dataError = ref({
  brand: false,
  model: false,
  version: false,
  year: false,
  state: false,
  price: false
});

const etBrands = ref(new Map);
const etBrandsPlain = ref(new Map);
const etModels = ref(new Map);
const etVersions = ref(new Map);
const etStates = computed(() => {
  const states = new Map();
  states.set('new', 'Nowy');
  states.set('used', 'Używany');
  return states;
});

const etYears = computed(() => {
  const years = new Map();
  const from = parseInt((new Date()).getFullYear());
  const to = from - 4;
  for (let y = from; y >= to; y--) {
    years.set(y, y);
  }
  return years;
})

const getBrands = async () => {
  const result = await backend.getBrandsET();
  if (result.data.errors) result.data = [];
  return sortData(result.data);
}

const getPromotedBrands = async () => {
  const cmsBrands = await backend.getBrands();
  return sortData(toRaw(cmsBrands.data).filter(i => i.promoted));
}

const getModels = async (brandId) => {
  const result = await backend.getModelsET(brandId);
  if (result.data.errors) result.data = [];
  return sortData(result.data);
}

const getVersions = async (modelId) => {
  const result = await backend.getVersionsET(modelId);
  if (result.data.errors) result.data = [];
  return sortData(result.data);
}

const clearAll = () => {
  refVersions.value.reset();
  refModels.value.reset();
}

const clearVersions = () => {
  refVersions.value.reset();
}

const submit = () => {
  const fields = Object.keys(dataError.value);
  fields.forEach(field => {
    const value = modelData.value[field];
    if (field === 'price') {
      if (!parseInt(value)) {
        dataError.value[field] = 'Wartość liczbowa wymagana';
      } else if (parseInt(value) > 10000000) {
        dataError.value[field] = 'Wartość jest zbyt duża';
      } else {
        dataError.value[field] = false;
      }
    } else {
      dataError.value[field] = !value.length ? 'Pole wymagane' : false
    }
  });

  const pass = fields.reduce((acc, field) => acc && !!!dataError.value[field], true);
  if (pass) {

    digitalOrder.createDigitalOrderFromMarket(
      etBrandsPlain.value.get(modelData.value.brand[0]),
      etModels.value.get(modelData.value.model[0]),
      etVersions.value.get(modelData.value.version[0]),
      modelData.value.year[0],
      modelData.value.state[0] === 'new' ? 1 : 0,
      modelData.value.price,
      modelData.value.model[0]
    );
  }

}

const changeClientType = () => {
  const params = getCurrentParams();
  params.client = modelData.value.client;
  setCurrentParams(params);
}

onMounted(async () => {
  const brands = await getBrands();
  const promoted = await getPromotedBrands();
  const groups = new Map();
  const promotedMap = new Map();

  etBrandsPlain.value = new Map(brands);

  promoted.forEach(b => {
    const brandId = brands.getKeyByValue(b.name);
    if (brandId) {
      promotedMap.set(brandId, b.name);
      brands.delete(brandId);
    }
  })

  groups.set('Popularne', promotedMap);
  groups.set('Pozostałe', brands);

  etBrands.value = groups;
  changeClientType();
});

watch(
  () => modelData.value.brand,
  (brandId) => {
    etModels.value = new Map;
    etVersions.value = new Map;
    if (brandId) {
      nextTick(async () => {
        etModels.value = await getModels(brandId);
      });
    }
  }
)

watch(
  () => modelData.value.model,
  (modelId) => {
    if (modelId) {
      etVersions.value = new Map;
      if (modelId) {
        nextTick(async () => {
          etVersions.value = await getVersions(modelId);
        });
      }
    }
  }
)

</script>

<template>
  <div :class="['rounded-lg shadow-box bg-white']">
    <div class="relative">
      <FormStateBlend ref="refStateBlend" />
      <div class="p-6">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div>
            <div class="text-sm font-extralight mb-1">klient</div>
            <InputState :states="leasingParamsDefinition.client"
                        v-model:value="modelData.client"
                        @change="changeClientType"
            />
          </div>
          <div class="hidden md:block"></div>
          <InputDropDown label="Marka" ref="refBrands" :options="etBrands" v-model="modelData.brand" :error="dataError.brand" @change="clearAll" grouped with-search />
          <InputDropDown label="Model" ref="refModels" :options="etModels" v-model="modelData.model" :error="dataError.model" @change="clearVersions" />
          <InputDropDown label="Wersja" ref="refVersions" :options="etVersions" v-model="modelData.version" :error="dataError.version" />
          <InputDropDown label="Stan samochodu" :options="etStates" v-model="modelData.state" :error="dataError.state" />
          <InputDropDown label="Rok produkcji" :options="etYears" v-model="modelData.year" :error="dataError.year" />
          <InputText label="Cena samochodu (zł netto)" v-model="modelData.price" :error="dataError.price" />
        </div>
        <div class="pt-6">
          <ButtonRounded color="azure" class="max-w-[300px] mx-auto" @click="submit">Sprawdź ratę</ButtonRounded>
        </div>
      </div>
    </div>
  </div>
</template>
